import { MedicationModel } from '@cuidador/database'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/AddRounded'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import BuyMedicationDialog from '../../components/BuyMedicationDialog'
import LoadingBackdrop from '../../components/LoadingBackdrop'
import MedicationCard from '../../components/MedicationCard'
import PendingRegistrationBanner from '../../components/PendingRegistrationBanner'
import ReportDownloadButton from '../../components/ReportDownloadButton'
import StyledButton from '../../components/StyledButton'
import { AuthContext } from '../../contexts/auth'
import useCanAccess from '../../hooks/useCanAccess'
import useMedication from '../../hooks/useMedication'
import {
  ButtonContainer,
  Container,
  ListContainer,
  ListContainerWithBorder,
  StyledAccordion,
  StyledFab,
  StyledTypography,
  TypographyContainer,
  StyledBuyButton,
} from './styles'
import { sortMedicationsByDoseTime } from './utils'
import { Header } from '../../components/Header'
import { BackButton } from '../../components/BackButton'
import { PageTitle } from '../../components/PageTitle'
import { PscButton } from '../../components/PscButton'
import { useDoseCerta } from '../../hooks/useDoseCerta'

const Medication: React.FC = () => {
  const MEDICATION_ROUTE = '/medicamentos_v2'
  const history = useHistory()
  const [finishedMedications, setFinishedMedications] = useState<
    MedicationModel[]
  >()
  const [totalFinishedMedications, setTotalFinishedMedications] = useState(0)
  const [page, setPage] = useState(0)
  const { userInfo } = useContext(AuthContext)
  const {
    getAllByPatientId: getCurrentMedications,
    byId,
    ids: currentMedicationIds,
    loading,
    createReportByPatientId,
    getReportDownloadLinkByPatientId,
    getScheduledMedicationsByPatientId: getFinishedMedications,
  } = useMedication()
  const {
    isAllowedToRead: isAllowedToReadMedication,
    isAllowedToCreate: isAllowedToCreateMedication,
  } = useCanAccess('care/medication')
  const { isAllowedToCreate: isAllowedToCreateMedicationReport } = useCanAccess(
    'report/medication'
  )
  const { isAllowedToRead: isAllowedToReadMedicationReport } = useCanAccess(
    'media/patient/report/medication'
  )
  const { createRequest } = useDoseCerta()
  const [downloadLink, setDownloadLink] = useState('')
  const [isBuyDialogOpen, setIsBuyDialogOpen] = useState(false)
  const patientId = userInfo?.activePatientId

  useEffect(() => {
    if (!isAllowedToReadMedication) {
      toast.error('Você não tem permissão para visualizar essa página')
      history.goBack()
      return
    }
    if (!patientId) return

    getCurrentMedications(patientId, {
      orderBy: 'eventSchedule.frequencyEndsAt',
      order: 'desc',
      minFrequencyEndsAt: new Date().toISOString(),
    })
    getFinishedMedications(patientId, {
      orderBy: 'eventSchedule.frequencyEndsAt',
      order: 'desc',
      page,
      'eventSchedule.frequencyEndsAt(max)': new Date().toISOString(),
    }).then((response) => {
      if (response?.total > 0) {
        setTotalFinishedMedications(response.total)
        setFinishedMedications(
          finishedMedications
            ? [...finishedMedications, ...response.results]
            : [...response.results]
        )
      }
    })
  }, [patientId, page])

  const pageIncrement = () => {
    setPage(page + 1)
  }

  const handleCreateReportAndGetDownloadLink = async () => {
    createMedicationReport()
      .then(() => getMedicationReportDownloadUrl())
      .then((signedUrl) => setDownloadLink(signedUrl))
  }

  const createMedicationReport = async () => {
    try {
      await createReportByPatientId(Number(userInfo?.activePatientId))
      toast.success('Relátorio de medicamentos gerado com sucesso')
    } catch (err) {
      toast.error('Erro ao gerar relatório de medicamentos')
    }
  }

  const getMedicationReportDownloadUrl = async (): Promise<string> => {
    try {
      const { signedUrl } = await getReportDownloadLinkByPatientId(
        Number(userInfo?.activePatientId)
      )
      return signedUrl
    } catch (err) {
      toast.error('Erro ao baixar relatório de medicamentos')
      return ''
    }
  }

  const buyMedications = async () => {
    const medications = currentMedicationIds.map((id) => byId[id])
    createRequest(medications)
    setIsBuyDialogOpen(false)
  }

  return (
    <>
      <Header
        leftContent={
          <BackButton action={() => history.push('/cadastros')} />
        }
        centerContent={
          <PageTitle title='Medicamentos' />
        }
        rightContent={
          <PscButton />
        }
      />
      <PendingRegistrationBanner />
      <LoadingBackdrop loading={loading} />
      <Container>
        <ListContainer>
          {currentMedicationIds.length <= 0 ? (
            <TypographyContainer>
              <Typography variant="h5">Não há medicamentos em uso.</Typography>
              <StyledTypography variant="subtitle1">
                Não existem medicamentos em uso cadastrados no momento.
              </StyledTypography>
            </TypographyContainer>
          ) : (
            <>
              <TypographyContainer>
                <Typography variant="h6">Lista de medicamentos</Typography>
              </TypographyContainer>
              {isAllowedToReadMedicationReport &&
                isAllowedToCreateMedicationReport && (
                  <ReportDownloadButton
                    createTitle="Clique aqui para gerar relatório de medicamentos em uso"
                    downloadtitle="Clique aqui para baixar o relatório de medicamentos em uso"
                    onGenerateReportClick={handleCreateReportAndGetDownloadLink}
                    downloadLink={downloadLink}
                  />
                )}
              <StyledTypography variant="h6" align="left">
                {`Medicamentos em uso (${currentMedicationIds.length})`}
              </StyledTypography>
              {currentMedicationIds
                .map((id) => byId[id])
                .sort(sortMedicationsByDoseTime)
                .map((medication) => (
                  <MedicationCard
                    key={medication.id}
                    medication={medication}
                    route={MEDICATION_ROUTE}
                  />
                ))}
            </>
          )}
        </ListContainer>
        {currentMedicationIds.length > 0 && (
          <StyledBuyButton
            onClick={() => setIsBuyDialogOpen(true)}
            color="secondary"
            data-testid="buy-medications"
          >
            Comprar medicamentos
          </StyledBuyButton>
        )}
        {finishedMedications && (
          <ListContainerWithBorder>
            <StyledAccordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                data-testid={'finished-medication-accordion'}
              >
                <StyledTypography variant="h6" align="left">
                  {`Medicamentos finalizados (${totalFinishedMedications})`}
                </StyledTypography>
              </AccordionSummary>
              {finishedMedications.map((medication) => (
                <MedicationCard key={medication.id} medication={medication} />
              ))}
              {finishedMedications.length < totalFinishedMedications && (
                <ButtonContainer>
                  <StyledButton
                    data-testid="show-more"
                    size="medium"
                    color="secondary"
                    onClick={pageIncrement}
                  >
                    Ver mais
                  </StyledButton>
                </ButtonContainer>
              )}
            </StyledAccordion>
          </ListContainerWithBorder>
        )}

        {isAllowedToCreateMedication && (
          <StyledFab to={`${MEDICATION_ROUTE}/novo`}>
            <AddIcon />
          </StyledFab>
        )}
      </Container>
      <BuyMedicationDialog
        isOpen={isBuyDialogOpen}
        onClose={() => setIsBuyDialogOpen(false)}
        onConfirm={buyMedications}
        isLoading={false}
      />
    </>
  )
}

export default Medication
