import { DialogTitle, Typography } from '@material-ui/core';
import React from 'react';
import StyledDialog from '../../components/StyledDialog';
import {
  CenterLayout,
  StyledDialogContent,
  StyledTextButton,
  StyledWhiteButton,
} from './styles';

type BuyMedicationDialogProps = {
  onClose: () => void;
  onConfirm: () => void;
  isOpen: boolean;
  isLoading: boolean;
};

export default function BuyMedicationDialog({
  isOpen,
  onConfirm,
  onClose,
  isLoading,
}: BuyMedicationDialogProps) {
  return (
    <StyledDialog open={isOpen} onClose={onClose} data-testid="buy-dialog">
      <StyledDialogContent>
        <DialogTitle>
          Receba os medicamentos em casa pela parceria com a <b>dose certa (Raia/Drogasil)</b>
        </DialogTitle>
        <Typography align="center" paragraph>
          Mande sua lista de medicamentos e a <b>dose certa (Raia/Drogasil)</b> entrará em contato com você em breve com o orçamento.
        </Typography>
        <CenterLayout>
          <StyledWhiteButton
            onClick={onConfirm}
            color="inherit"
            size="large"
            data-testid="confirm-dialog"
            disabled={isLoading}
          >
            Quero um orçamento
          </StyledWhiteButton>
          <StyledTextButton
            onClick={onClose}
            color="inherit"
            data-testid="close-dialog"
            disabled={isLoading}
          >
            Não, obrigado
          </StyledTextButton>
        </CenterLayout>
      </StyledDialogContent>
    </StyledDialog>
  );
}
