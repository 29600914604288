import React from 'react';
import {
  CardBox,
  TypographyContainer,
  IconContainer,
  WeekDayContainer,
  TimerContainer,
  TitleContainer,
} from './styles';
import { EventSubCategoryModel } from '@cuidador/database';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as ClockIcon } from '../../assets/Icon-clock.svg';
import format from 'date-fns/format';
import {
  frequencyOptions,
  getSelectedWeekDays,
  getCustomInterval,
  FrequencyType,
} from '../RoutinesUpsertForm/utils';
import ptBrLocale from 'date-fns/locale/pt-BR';
import { getLocalWeeklySchedule, weekdayMap } from '../../utils/date';

interface CardProps {
  id: Id;
  subCategory?: EventSubCategoryModel[];
  name?: string;
  description?: string;
  mon?: boolean;
  tue?: boolean;
  wed?: boolean;
  thu?: boolean;
  fri?: boolean;
  sat?: boolean;
  sun?: boolean;
  frequencyRule?: string;
  frequencyStartsAt?: string;
  frequencyEndsAt?: string | undefined | null;
}

const RoutineCard: React.FC<CardProps> = ({
  id,
  subCategory,
  name,
  description,
  mon,
  tue,
  wed,
  thu,
  fri,
  sat,
  sun,
  frequencyRule,
  frequencyStartsAt,
  frequencyEndsAt
  
}) => {
  const frequency = frequencyOptions.find(
    (frequency) => frequency.value === frequencyRule
  );

  const getWeekDay = () => {
    const frequencyCardTypes = ['1w', '2w'];
    if (frequency && typeof frequency.value === 'string' &&frequencyCardTypes.includes(frequency.value))
      return format(new Date(frequencyStartsAt || ''), 'EEE', {
        locale: ptBrLocale,
      });
    return '';
  };

  const getDaysText = () => {
    const areAllDaysSelected = mon && tue && wed && thu && fri && sat && sun;
    const areAllWeekDaysSelected = mon && tue && wed && thu && fri;
    const areSomeDaysSelected = !areAllDaysSelected || !areAllWeekDaysSelected;
  
    if (frequencyRule === '1d') {
      if (areAllDaysSelected) {
        return 'Todos os dias';
      } else if (areAllWeekDaysSelected) {
        return 'Segunda a Sexta';
      } else if (areSomeDaysSelected) {
        return `Apenas alguns dias (${getSelectedWeekdaysText()})`;
      }
    } else {
      const frequencyTimeString = frequencyRule?.replace(/.$/, '');
      return `A cada ${frequencyTimeString} dias`;
    }
  };

  const getSelectedWeekdaysText = () => {
    const utcSchedule = { mon, tue, wed, thu, fri, sat, sun };
    const date = new Date(frequencyStartsAt || '');
    const utcTime = `${date
      .getUTCHours()
      .toString()
      .padStart(2, '0')}:${date.getUTCMinutes().toString().padStart(2, '0')}`;
    const localSchedule = getLocalWeeklySchedule(utcSchedule, utcTime);
  
    const selectedWeekDays: string[] = [];
    if (localSchedule.mon) selectedWeekDays.push(weekdayMap.mon);
    if (localSchedule.tue) selectedWeekDays.push(weekdayMap.tue);
    if (localSchedule.wed) selectedWeekDays.push(weekdayMap.wed);
    if (localSchedule.thu) selectedWeekDays.push(weekdayMap.thu);
    if (localSchedule.fri) selectedWeekDays.push(weekdayMap.fri);
    if (localSchedule.sat) selectedWeekDays.push(weekdayMap.sat);
    if (localSchedule.sun) selectedWeekDays.push(weekdayMap.sun);
  
    return selectedWeekDays.reduce(
      (accumulatorString, weekday, index) =>
        `${accumulatorString}${index > 0 ? ', ' : ''}${weekday.toLowerCase()}`,
      ''
    );
  };
  
  const routineEnded = new Date(frequencyEndsAt || '') < new Date()

  const fixedEventTimeLocal = format(new Date(frequencyStartsAt!), 'HH:mm');

  return (
    <CardBox data-testid={`routineCard-${id}`}>
      <IconContainer>
        <TimerContainer>
          <Typography variant="h6">
            {frequency && getWeekDay() && `${getWeekDay()} - `}
            {fixedEventTimeLocal}
          </Typography>
        </TimerContainer>

        <ClockIcon />
      </IconContainer>

      <TypographyContainer>
        <TitleContainer>
          <Typography variant="h6">
            {subCategory && subCategory.length > 0 && subCategory[0].name}
          </Typography>
        </TitleContainer>

        <Typography variant="subtitle1">
          {name?.trim()}
        </Typography>

        <Typography variant="subtitle1">
          <WeekDayContainer>
            {getDaysText()}
          </WeekDayContainer>
        </Typography>

        <Typography variant="subtitle2">
          {description}
        </Typography>

        <Typography variant='subtitle2' style={{ color: routineEnded ? 'yellow' : '' }}>
          {routineEnded ? 'Rotina finalizada' : 'Rotina em andamento'}
        </Typography>
      </TypographyContainer>
    </CardBox>
  );
};

export default RoutineCard;
