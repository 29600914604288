import { EventScheduleModel } from '@cuidador/database';
import { format, parse } from 'date-fns';
import * as Yup from 'yup';
import {
  getLocalWeeklySchedule,
  getUTCWeeklySchedule,
  weekdayMap,
} from '../../utils/date';
import { EVERYDAY, FormValues, MONDAY_TO_FRIDAY } from './index';

export enum FrequencyType {
  EveryDay = 'Todos os dias',
  MondayToFriday = 'Segunda a sexta',
  SomeDays = 'Alguns dias da semana',
  Custom = 'Intervalos fixos',
}

export const validationSchema = Yup.object().shape({
  name: Yup.string().optional(),
  subCategoryId: Yup.string().required('Por favor, insira o tipo da rotina.'),
  time: Yup.string().trim().required('Por favor, insira a hora.'),
  frequencyRule: Yup.string().when('controlFrequency', {
    is: FrequencyType.Custom,
    then: Yup.string().required('Por favor, insira uma frequência.'),
  }),
  frequencyStartsAt: Yup.string().required(
    'Por favor, insira uma data inicial.'
  ),
  frequencyEndsAt: Yup.string().when('continuous', {
    is: false,
    then: Yup.string().required('Por favor, insira uma data final.'),
  }),
  days: Yup.array().when(['customInterval'], {
    is: (customInterval) => 
      customInterval === FrequencyType.EveryDay || 
      customInterval === FrequencyType.MondayToFriday || 
      customInterval === FrequencyType.SomeDays,
    then: Yup.array().min(1, 'Por favor, selecione o(s) dia(s) da semana.'),
  }),
  description: Yup.string().max(2000, 'Deve conter no máximo 2000 caracteres.'),
  customRangeInDays: Yup.string().when(['rangeInDays','customInterval'], {
    is: (rangeInDays,customInterval) => rangeInDays === 'custom' && customInterval === FrequencyType.Custom,
    then: Yup.string().required('Por favor, insira um intervalo.'),
  }),
  rangeInDays: Yup.string().when(['customInterval'], {
    is: (customInterval) =>
      customInterval === FrequencyType.Custom,
    then: Yup.string().required('Por favor, insira um intervalo.'),
  }),
});

export const frequencyOptions: {
  value: FormValues['rangeInDays'];
  name: string;
}[] = [
  { value: '2d', name: 'A cada 2 dias' },
  { value: '3d', name: 'A cada 3 dias' },
  { value: '5d', name: 'A cada 5 dias' },
  { value: '7d', name: 'A cada 7 dias' },
  { value: '15d', name: 'A cada 15 dias' },
  { value: '30d', name: 'A cada 30 dias' },
  { value: 'custom', name: 'Incluir novo intervalo' },
];

export const routineModelToFormValues = (
  routine: EventScheduleModel
): FormValues => {
  let formattedDate;
  let formattedTime;
  let formattedEndDate;

  if (routine?.frequencyStartsAt) {
    formattedDate = format(new Date(routine?.frequencyStartsAt), 'yyyy-MM-dd');
    formattedTime = format(new Date(routine?.frequencyStartsAt), 'HH:mm');
    if (routine?.frequencyEndsAt) {
      formattedEndDate = format(
        new Date(routine?.frequencyEndsAt),
        'yyyy-MM-dd'
      );
    }
  }

  const { rangeInDays, customRangeInDays } = getRangesInDays(
    routine?.frequencyRule || ''
  );

  const subCategoryId = routine.subCategory?.length
    ? routine?.subCategory[0]?.id
    : '';

  return {
    name: routine.name || '',
    description: routine.description || '',
    subCategoryId,
    time: formattedTime || '',
    frequencyStartsAt: formattedDate || format(new Date(), 'yyyy-MM-dd'),
    frequencyEndsAt: formattedEndDate || '',
    days: getSelectedWeekDays(routine),
    frequencyRule: routine?.frequencyRule || '',
    customInterval: getCustomInterval(getSelectedWeekDays(routine), routine?.frequencyRule),
    continuous: !formattedEndDate,
    rangeInDays: rangeInDays,
    customRangeInDays: customRangeInDays,
  } as FormValues;
};

export const formDataToRoutineModel = (
  formData: FormValues,
  patientId: Id
): EventScheduleModel => {
  const frequencyStartsAt = parse(
    `${formData.frequencyStartsAt} ${formData.time}`,
    'yyyy-MM-dd HH:mm',
    new Date()
  ).toISOString();

  let frequencyEndsAt = undefined;
  if (formData.frequencyEndsAt) {
    frequencyEndsAt = parse(
      `${formData.frequencyEndsAt}`,
      'yyyy-MM-dd',
      new Date()
    ).toISOString();
  }

  const localSchedule = {
    mon: true,
    tue: true,
    wed: true,
    thu: true,
    fri: true,
    sat: true,
    sun: true,
  };
  if (formData.days && formData.days.length > 0) {
    localSchedule.mon = formData.days.includes(weekdayMap.mon);
    localSchedule.tue = formData.days.includes(weekdayMap.tue);
    localSchedule.wed = formData.days.includes(weekdayMap.wed);
    localSchedule.thu = formData.days.includes(weekdayMap.thu);
    localSchedule.fri = formData.days.includes(weekdayMap.fri);
    localSchedule.sat = formData.days.includes(weekdayMap.sat);
    localSchedule.sun = formData.days.includes(weekdayMap.sun);
  }
  const utcSchedule = getUTCWeeklySchedule(
    localSchedule,
    String(formData.time)
  );

  const frequencyRule = getFrequencyRule(formData);

  return {
    scheduleType: 'frequency',
    name: formData.name,
    description: formData.description,
    subCategory: [{ id: Number(formData.subCategoryId) }],
    patientId: patientId as number,
    ...utcSchedule,
    frequencyStartsAt,
    frequencyRule: frequencyRule || '1d',
    frequencyEndsAt,
  };
};

const getRangesInDays = (frequencyRule: string) => {
  let rangeInDays;
  let customRangeInDays;
  const notCustomIntervalOptions = ['1d', '2d', '3d', '5d', '7d', '15d', '30d'];
  if(notCustomIntervalOptions.includes(frequencyRule)) {
    rangeInDays = (frequencyRule === '1d') ? undefined : frequencyRule;
  } else {
    rangeInDays = 'custom';
    customRangeInDays = Number(frequencyRule.split('d')[0]);
  }

  return {
    rangeInDays,
    customRangeInDays,
  };
};

export const getCustomInterval = (days: string[], freqRule: string | undefined) => {
  const frequencyRule = freqRule || '1d';
  
  const isEveryDay =
    days.length === 7 && days.every((day) => EVERYDAY.includes(day));
  const isMondayToFriday =
    days.length === 5 && days.every((day) => MONDAY_TO_FRIDAY.includes(day));
  const isSomeDays = !isEveryDay && !isMondayToFriday;
  if (frequencyRule === '1d') {
    if (isEveryDay) return FrequencyType.EveryDay;
    if (isMondayToFriday) return FrequencyType.MondayToFriday;
    if (isSomeDays) return FrequencyType.SomeDays;
  }
  return FrequencyType.Custom;
};

export const getSelectedWeekDays = (routine: EventScheduleModel) => {
  var { mon, tue, wed, thu, fri, sat, sun } = routine || {};

  if(routine.id === null || routine.id === undefined) {
    mon = true;
    tue = true;
    wed = true;
    thu = true;
    fri = true;
    sat = true;
    sun = true;
  }

  const utcSchedule = { mon, tue, wed, thu, fri, sat, sun };  
  const date = new Date(routine?.frequencyStartsAt || '');
  const utcTime = `${date
    .getUTCHours()
    .toString()
    .padStart(2, '0')}:${date.getUTCMinutes().toString().padStart(2, '0')}`;
  const localSchedule = getLocalWeeklySchedule(utcSchedule, utcTime);

  const selectedWeekDays: string[] = [];
  if (localSchedule.mon) selectedWeekDays.push(weekdayMap.mon);
  if (localSchedule.tue) selectedWeekDays.push(weekdayMap.tue);
  if (localSchedule.wed) selectedWeekDays.push(weekdayMap.wed);
  if (localSchedule.thu) selectedWeekDays.push(weekdayMap.thu);
  if (localSchedule.fri) selectedWeekDays.push(weekdayMap.fri);
  if (localSchedule.sat) selectedWeekDays.push(weekdayMap.sat);
  if (localSchedule.sun) selectedWeekDays.push(weekdayMap.sun);

  return selectedWeekDays;
};

export const getFrequencyRule = (formData: FormValues) => {
  if (formData.customInterval !== FrequencyType.Custom) return '1d';
  if (formData.rangeInDays !== 'custom') return formData.rangeInDays;
  return `${formData.customRangeInDays}d`;
};
