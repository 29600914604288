import { Radio, RadioGroup } from '@material-ui/core';
import { format } from 'date-fns';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import isEqual from 'lodash/isEqual';
import React, { useEffect, useState } from 'react';
import useSubCategory from '../../hooks/useSubCategory';
import {
  FormCardContainer,
  HelperContainer,
  NewStyledSelect as StyledSelect,
  StyledBoldTitle,
  StyledFormikTextField as FormikTextField,
} from '../FormCardContainer';
import FormikWeekDaySelect from '../Forms/FormikWeekDaySelect';
import MedicBalloon from '../MedicBalloon';
import StyledFormControlLabel from '../StyledFormControlLabel';
import StyledMenuItem from '../StyledMenuItem';
import {
  Backdrop,
  BackdropCircularProgress,
  Button,
  Centralizer,
  ContentContainer,
  Form,
  StyledCheckbox,
  StyledRow,
} from './styles';
import { FrequencyType, frequencyOptions, validationSchema } from './utils';
import ErrorText from '../MedicationInsertForm/MedicationSecondStep/ErrorText';

export interface FormValues {
  name?: string;
  description?: string;
  subCategoryId?: Id;
  time?: string;
  days: string[];
  frequencyRule: string;
  frequencyStartsAt: string;
  frequencyEndsAt?: string;
  customInterval?: FrequencyType;
  continuous?: boolean;
  rangeInDays?: '2d' | '3d' | '5d' | '7d' | '15d' | '30d' | 'custom';
  customRangeInDays?: number;
}

export const EVERYDAY = ['Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab', 'Dom'];
export const MONDAY_TO_FRIDAY = ['Seg', 'Ter', 'Qua', 'Qui', 'Sex'];

const AppointmentsUpsertForm: React.FC<{
  initialValues: FormValues;
  innerRef?: React.Ref<FormikProps<FormValues>>;
  onSubmit: (values: FormValues, continuousAdd?: boolean) => Promise<void>;
  disabled?: boolean;
}> = ({ initialValues, innerRef, onSubmit, disabled }) => {
  const {
    getOnlyForRoutines: getSubCategories,
    ids: subCategoryIds,
    byId,
    loading: loadingSubCategory,
  } = useSubCategory();

  const [continuousAdd, setContinuousAdd] = useState(false);

  useEffect(() => {
    getSubCategories();
  }, []);

  const handleSubmit = async (
    values: FormValues,
    { resetForm }: FormikHelpers<FormValues>
  ) => {
    await onSubmit(values, continuousAdd);
    resetForm();
  };

  if (loadingSubCategory) {
    return (
      <Backdrop open={loadingSubCategory}>
        {loadingSubCategory && (
          <BackdropCircularProgress data-testid="table-backdrop-spinner" />
        )}
      </Backdrop>
    );
  }

  const finalDate = format(new Date('12/31/9999'), 'yyyy-MM-dd');
  const initialDate = format(new Date(), 'yyyy-MM-dd');

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnChange={false}
        innerRef={innerRef}
      >
        {({
          values,
          isSubmitting,
          touched,
          errors,
          setFieldValue,
          handleChange,
        }) => {
          const handleSelectedCustomIntervalChange = (
            e: React.ChangeEvent<HTMLInputElement>
          ) => {
            switch (e.target.value as FrequencyType) {
              case FrequencyType.EveryDay:
                setFieldValue('customInterval', FrequencyType.EveryDay);
                setFieldValue('days', EVERYDAY);
                break;
              case FrequencyType.MondayToFriday:
                setFieldValue('customInterval', FrequencyType.MondayToFriday);
                setFieldValue('days', MONDAY_TO_FRIDAY);
                break;
              case FrequencyType.SomeDays:
                setFieldValue('customInterval', FrequencyType.SomeDays);
                setFieldValue('days', []);
                break;
              case FrequencyType.Custom:
                setFieldValue('customInterval', FrequencyType.Custom);
                setFieldValue('days', []);
                break;
              default:
                setFieldValue('customInterval', FrequencyType.EveryDay);
                setFieldValue('days', EVERYDAY);
                break;
            }
          };

          const handleSelectedDaysChange = () => {            
            if (!values.days) return;
            const isEveryDay =
              values.days.length === 7 &&
              values.days.every((day) => EVERYDAY.includes(day));
        
            const isMondayToFriday =
              values.days.length === 5 &&
              values.days.every((day) => MONDAY_TO_FRIDAY.includes(day));

            const isSomeDays = !isEveryDay && !isMondayToFriday;
            if (values.customInterval !== FrequencyType.Custom) {   
              if (isEveryDay) setFieldValue('customInterval', FrequencyType.EveryDay);
              if (isMondayToFriday)
                setFieldValue('customInterval', FrequencyType.MondayToFriday);
              if (isSomeDays) setFieldValue('customInterval', FrequencyType.SomeDays);
            }
          };

          useEffect(() => {
            handleSelectedDaysChange();
          }, [values.days]);

          return (
            <Form
              // noValidate disables browser default validation
              noValidate={true}
            >
              <MedicBalloon text="Rotinas de cuidado incluem higiene, alimentação, exercícios físicos e mentais, entre outros. Coloque detalhes para que o dia-a-dia seja mais confortável e agradável." />
              <ContentContainer>
                <FormCardContainer>
                  <StyledBoldTitle variant="subtitle2">
                    Selecione o tipo da rotina, crie nome e horário para ela
                  </StyledBoldTitle>

                  <StyledSelect
                    variant="outlined"
                    label="Tipo de rotina"
                    placeholder="Tipo de rotina"
                    name="subCategoryId"
                    SelectDisplayProps={{
                      'data-testid': 'subCategoryId',
                    }}
                    color="secondary"
                    size="small"
                    disabled={disabled}
                  >
                    {subCategoryIds
                      .map((id) => byId[id])
                      .map((item) => (
                        <StyledMenuItem
                          key={item.id}
                          value={item.id}
                          color="secondary"
                        >
                          {item.name}
                        </StyledMenuItem>
                      ))}
                  </StyledSelect>

                  <FormikTextField
                    variant="outlined"
                    label="Nome (opcional)"
                    placeholder="Nome (opcional)"
                    color="secondary"
                    id="name"
                    inputProps={{ 'data-testid': 'name' }}
                    name="name"
                    margin="normal"
                    autoComplete="off"
                    size="small"
                  />

                  <FormikTextField
                    variant="outlined"
                    label="Horário"
                    color="secondary"
                    id="time"
                    inputProps={{ 'data-testid': 'time' }}
                    name="time"
                    margin="dense"
                    autoComplete="off"
                    type="time"
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    helperText={
                      <HelperContainer>
                        {touched.time && errors.time}
                      </HelperContainer>
                    }
                  />
                </FormCardContainer>

                <FormCardContainer>
                  <StyledBoldTitle variant="subtitle2">
                    Dias
                  </StyledBoldTitle>

                  <RadioGroup
                    aria-label="custom-interval"
                    name="customInterval"
                    value={values.customInterval}
                    onChange={handleSelectedCustomIntervalChange}
                  >
                    {Object.values(FrequencyType).map((label, index) => (
                      <StyledFormControlLabel
                        key={index}
                        value={label}
                        control={<Radio size="small" />}
                        label={label}
                        disabled={disabled}
                      />
                    ))}
                  </RadioGroup>                  
                  {values.customInterval === FrequencyType.Custom ? (
                    <>
                      <StyledSelect
                        variant="outlined"
                        placeholder="Incluir novo intervalo"
                        label="Intervalo em dias"
                        name="rangeInDays"
                        value={values.rangeInDays}
                        SelectDisplayProps={{
                          'data-testid': 'rangeInDays',
                        }}
                        color="secondary"
                        size="small"
                        disabled={disabled}
                      >
                        {frequencyOptions.map((frequencyOption, index) => (
                          <StyledMenuItem
                            key={index}
                            value={frequencyOption.value}
                            color="secondary"
                          >
                            {frequencyOption.name}
                          </StyledMenuItem>
                        ))}
                      </StyledSelect>
                      {errors.rangeInDays && (
                        <ErrorText errorMessage={errors.rangeInDays} />
                      )}
                      {values.rangeInDays === 'custom' && (
                        <>
                          <FormikTextField
                            id="customRangeInDays"
                            name="customRangeInDays"
                            label="Intervalo em dias"
                            placeholder="Intervalo em dias"
                            inputProps={{ 'data-testid': 'customRangeInDays' }}
                            variant="outlined"
                            color="secondary"
                            margin="normal"
                            autoComplete="off"
                            size="small"
                            type="number"
                            disabled={disabled}
                          />
                          {errors.customRangeInDays && (
                            <ErrorText errorMessage={errors.customRangeInDays} />
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <FormikWeekDaySelect name="days" />
                  )}
                </FormCardContainer>
                
                <FormCardContainer>
                  <StyledBoldTitle variant="subtitle2">
                    Por quanto tempo?
                  </StyledBoldTitle>

                  <StyledFormControlLabel
                    control={
                      <StyledCheckbox
                        name="continuous"
                        id="continuous"
                        inputProps={{
                          'aria-label': 'continuous use',
                        }}
                        checked={values.continuous || false}
                        onChange={handleChange}
                      />
                    }
                    label="Continuamente"
                  />
                  <StyledRow>
                    <StyledBoldTitle variant="subtitle2">
                      Data de início
                    </StyledBoldTitle>
                    <FormikTextField
                      value={values?.frequencyStartsAt}
                      color="secondary"
                      id="frequencyStartsAt"
                      inputProps={{
                        'data-testid': 'frequencyStartsAt',
                        min: initialDate,
                        max: finalDate,
                      }}
                      name="frequencyStartsAt"
                      autoComplete="off"
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      size="small"
                    />
                  </StyledRow>
                  {!values.continuous && (
                    <StyledRow>
                      <StyledBoldTitle variant="subtitle2">
                        Data de fim
                      </StyledBoldTitle>
                      <FormikTextField
                        value={values?.frequencyEndsAt || ''}
                        color="secondary"
                        id="frequencyEndsAt"
                        inputProps={{
                          'data-testid': 'frequencyEndsAt',
                          min: initialDate,
                          max: finalDate,
                        }}
                        name="frequencyEndsAt"
                        autoComplete="off"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        size="small"
                      />
                    </StyledRow>
                  )}
                </FormCardContainer>

                <FormCardContainer>
                  <StyledBoldTitle variant="subtitle2">
                    Instruções (opcional)
                  </StyledBoldTitle>

                  <FormikTextField
                    variant="outlined"
                    label="Instruções"
                    placeholder="Instruções"
                    color="secondary"
                    id="description"
                    inputProps={{ 'data-testid': 'description' }}
                    name="description"
                    margin="normal"
                    autoComplete="off"
                    size="small"
                  />
                </FormCardContainer>

                <Centralizer>
                  <Button
                    data-testid="submit-routine"
                    type="submit"
                    size="large"
                    color="inherit"
                    disabled={isSubmitting || isEqual(initialValues, values)}
                    onClick={() => setContinuousAdd(false)}
                  >
                    Salvar
                  </Button>
                  <Button
                    data-testid="submit-continuos-routine"
                    type="submit"
                    size="large"
                    color="inherit"
                    disabled={isSubmitting || isEqual(initialValues, values)}
                    onClick={() => setContinuousAdd(true)}
                  >
                    Salvar e adicionar nova
                  </Button>
                </Centralizer>
              </ContentContainer>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default AppointmentsUpsertForm;
