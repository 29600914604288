import { APIError } from '@cuidador/lib'
import { Typography } from '@material-ui/core'
import { AxiosError } from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import LoadingBackdrop from '../../components/LoadingBackdrop'
import PendingRegistrationBanner from '../../components/PendingRegistrationBanner'
import ShoppingListUpsertForm from '../../components/ShoppingListUpsertForm'
import { AuthContext } from '../../contexts/auth'
import useMedication from '../../hooks/useMedication'
import useShoppingList from '../../hooks/useShoppingList'
import { resolveErrorMessage } from '../../utils/error'
import { Container, StyledBuyButton } from './styles'
import BuyMedicationDialog from '../../components/BuyMedicationDialog'
import { Header } from '../../components/Header'
import { PageTitle } from '../../components/PageTitle'
import { PscButton } from '../../components/PscButton'
import { MenuButton } from '../../components/MenuButton'
import { SideMenu } from '../../components/SideMenu'
import { useDoseCerta } from '../../hooks/useDoseCerta'

const ShoppingList: React.FC = () => {
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false)
  const { userInfo } = useContext(AuthContext)
  const {
    getOneByPatientId: getOneShoppingListByPatientId,
    ids: shoppingListItemIds,
    byId: shoppingListItemById,
    loading: loadingShoppingList,
    postBuyShoppingListById,
  } = useShoppingList()

  const {
    getAllByPatientId: getCurrentMedications,
    byId: medicationById,
    ids: currentMedicationIds,
    loading: loadingMedications,
  } = useMedication()
  const { createRequest } = useDoseCerta()

  const [isBuyDialogOpen, setIsBuyDialogOpen] = useState(false)

  const { name = '', id: patientId } = userInfo?.activePatient || {}

  useEffect(() => {
    if (!patientId) return
    getOneShoppingListByPatientId(patientId)

    getCurrentMedications(patientId, {
      orderBy: 'eventSchedule.frequencyEndsAt',
      order: 'desc',
      minFrequencyEndsAt: new Date().toISOString(),
    })
  }, [patientId])

  const data = shoppingListItemIds.map((id) => shoppingListItemById[id])
  const textItem = {
    shoppingText: `${data[0]?.shoppingText || ''}`,
  }

  const listId = data[0]?.id
  const handleSubmit = () => {
    return postBuyShoppingListById(listId!)
      .then(() => {
        toast.success('Lista marcada como comprada com sucesso!')
      })
      .catch((err: AxiosError<APIError>) => {
        const displayMessage = resolveErrorMessage(err)
        toast.error(displayMessage)
      })
  }

  const buyMedications = async () => {
    const medications = currentMedicationIds.map((id) => medicationById[id])
    createRequest(medications)
    setIsBuyDialogOpen(false)
  }

  if (loadingMedications || loadingShoppingList) {
    return (
      <LoadingBackdrop loading={loadingMedications || loadingShoppingList} />
    )
  }

  return (
    <>
      <SideMenu
        isSideMenuOpen={isMenuOpen}
        handleToggleSideMenu={() => setMenuOpen(false)}
      />
      <Header
        leftContent={<MenuButton action={() => setMenuOpen(true)} />}
        centerContent={
          <PageTitle title={name} patientId={patientId!} />
        }
        rightContent={
          <PscButton />
        }
      />
      <PendingRegistrationBanner />
      <Container>
        <Typography variant="h6">Lista de compras</Typography>
        <Typography variant="body2" align="center">
          Acompanhe aqui a lista de itens indicados para compra pelo(a)
          cuidador(a).
        </Typography>
        <ShoppingListUpsertForm
          onSubmit={handleSubmit}
          initialValues={textItem}
        />
        {currentMedicationIds.length > 0 && (
          <StyledBuyButton
            onClick={() => setIsBuyDialogOpen(true)}
            color="inherit"
            data-testid="buy-medications"
          >
            Comprar medicamentos
          </StyledBuyButton>
        )}
      </Container>
      <BuyMedicationDialog
        isOpen={isBuyDialogOpen}
        onClose={() => setIsBuyDialogOpen(false)}
        onConfirm={buyMedications}
        isLoading={false}
      />
    </>
  )
}

export default ShoppingList
