import { APIError } from '@cuidador/lib';
import { AxiosError } from 'axios';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import ShiftScheduleUpsertForm, {
  EVERYDAY,
  formDataToShiftScheduleModel,
  FormValues,
} from '../../../../components/ShiftScheduleUpsertForm';
import { AuthContext } from '../../../../contexts/auth';
import useShiftSchedule from '../../../../hooks/useShiftSchedule';
import { resolveErrorMessage } from '../../../../utils/error';
import { Container } from './styles';
import { Header } from '../../../../components/Header'
import { PageTitle } from '../../../../components/PageTitle'
import { BackButton } from '../../../../components/BackButton'
import { PscButton } from '../../../../components/PscButton'

const formInitialValue = {
  name: '',
  startingTime: '',
  duration: '',
  days: EVERYDAY
};

const ShiftScheduleInsert: React.FC = () => {
  const { userInfo } = useContext(AuthContext);
  const { post } = useShiftSchedule();

  const patientId = userInfo?.activePatientId;

  const history = useHistory();
  const handleSubmit = (values: FormValues) => {
    if (!patientId) return;
    return post(patientId, formDataToShiftScheduleModel(values))
      .then(() => {
        history.replace('/plantoes/proximos');
      })
      .catch((err: AxiosError<APIError>) => {
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      });
  };

  return (
    <>
      <Header
        leftContent={
          <BackButton action={() => history.push('/cadastros')} />
        }
        centerContent={
          <PageTitle title='Adicionar plantão' />
        }
        rightContent={<PscButton />}
      />
      <Container>
        <ShiftScheduleUpsertForm
          initialValues={formInitialValue}
          onSubmit={handleSubmit}
        />
      </Container>
    </>
  );
};

export default ShiftScheduleInsert;
